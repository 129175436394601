/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.tripenhancer.app/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.tripenhancer.app)

Coded by www.tripenhancer.app

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React components
import MKBox from "components/MKBox";

import screenshotscollage from "../../../../assets/images/screenshotscollage.png";
import Grid from "@mui/material/Grid";

function BuiltByDevelopers() {
  return (
    <Grid container item xs={12} lg={12} sx={{ ml: { xs: 0, lg: 6 } }}>
      <MKBox
        component="img"
        src={screenshotscollage}
        alt={"TripEnhancer screenshots"}
        width="90%"
        my="auto"
        coloredShadow={"dark"}
      />
    </Grid>
  );
}

export default BuiltByDevelopers;
